import React, { useState, useEffect } from "react";
import "../../../assets/styles/pages/raceplaces/raceplaces.scss";
import gross from "../../../assets/images/raceinfo/gross.png";
import KanhaRunService from "../../services/kanha.service";
import { navigate } from "gatsby";
import { lStorage } from "../../utils/storage";
import config from "../../../assets/config";

function RaceCityPlaces() {
    const [countryList, setCountryList] = useState([]);
    const [selectedStateName, setSelectedStateName] = useState(""); // State name will be set based on URL parameter

    const existLocation = lStorage.get("selectedLocation");
    const kanhaApi = new KanhaRunService();

    function handleCityClick(cityDetails) {
        if (cityDetails.city_id === 1 && cityDetails.country === "India") {
            cityDetails.runType = "Timed Run";
        } else if (cityDetails.city_id !== 1 && cityDetails.country === "India") {
            cityDetails.runType = "Global Run";
        } else {
            cityDetails.runType = "Overseas";
        }
        try {
            if (
                existLocation?.country === cityDetails.country &&
                existLocation?.state_id === cityDetails.state_id &&
                existLocation?.city_id === cityDetails.city_id
            ) {
                localStorage.setItem("selectedLocation", JSON.stringify(cityDetails));
            } else {
                localStorage.setItem("selectedLocation", JSON.stringify(cityDetails));
                lStorage.remove("list");
            }
        } catch (error) {
            console.error("Error storing data in localStorage:", error);
        }
        navigate("/raceprice");
    }

    useEffect(() => {
        // Retrieve state parameter from URL
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get("state");
        if (state) {
            setSelectedStateName(state); // Set the state name from the URL
        }

        // Fetch country list data
        kanhaApi
            .getcountrylist()
            .then((response) => {
                if (
                    response.data.isError === false ||
                    response.data.statusCode === "200"
                ) {
                    setCountryList(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="mt-4 text-center">
                    <h3 className="title-heading">RACE LOCATIONS</h3>
                    {selectedStateName === "Maharashtra" &&(
                        <h6 className="mt-3">In Maharashtra, the run will be held in Kandivali on <b>December 8th 2024</b> and across other centers on <b>December 1st 2024</b></h6>
                    )}
                    
                </div>
                <div className="col-md-8 my-2 mx-auto">
                    <div className="race-title">
                        <h3>Choose where to run in {selectedStateName}</h3>
                    </div>
                    <div className="state-buttons justify-content-center">
                        {countryList
                            .filter((country) => country.Country_name === "India")
                            .flatMap((country) =>
                                country.states
                                    .filter((state) => state.state_name === selectedStateName) // Only display cities for the selected state
                                    .flatMap((state) => {
                                        const activeCities = state.cities.filter((city) => city.city_status === 1);
                                        return activeCities.length > 0 ? (
                                            activeCities.map((city) => (
                                                <div
                                                    key={city.city_id}
                                                    className="state-button telangana-class"
                                                    onClick={() =>
                                                        handleCityClick({
                                                            country: country.Country_name,
                                                            country_id: country.Country_id,
                                                            state: state.state_name,
                                                            state_id: state.state_id,
                                                            city: city.city_name,
                                                            city_id: city.city_id,
                                                        })
                                                    }
                                                >
                                                    {city.city_name}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="mt-5 w-100 text-center">
                                                <h4 key={`${state.state_id}-no-cities`}>
                                                    No cities available in {selectedStateName}.
                                                </h4>
                                            </div>
                                        );
                                    })
                            )}

                    </div>
                </div>
            </div>
            <div className="row my-4">
                <div className="gross-top-set">
                    <img className="img-fluid" src={gross} alt="gross" />
                </div>
            </div>
        </div>
    );
}

export default RaceCityPlaces;
